import { checkIsMobileApp, getUserAgent } from "@libs/utils/browser";
import { auth, getIdToken } from "@libs/utils/firebase/auth";
import { TOP_TITLE } from "../../constants";

type GTMEventProps = Record<string, string | undefined>;

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

export const GTMPageView = async (referrer: string): Promise<void> => {
  // firebaseのuidを取得するためcurrentUserを以下のメソッドでセットする
  await getIdToken();
  // トップ以外からトップに遷移するとき、前のページのタイトルを送信してしまっている対処
  if (window.location.pathname === "/") {
    window.document.title = TOP_TITLE;
  }
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      ...GTMEvents.pageView,
      userId: auth.currentUser?.uid,
      is_web_view: checkIsMobileApp(getUserAgent()),
      // サービス内遷移用のリファラー(クエリパラメータ付きのフルパス)。外部からの遷移のときは空文字が入る
      custom_page_referrer: referrer,
    });
};

export const GTMEvent = (event: GTMEventProps): void => {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      ...event,
      userId: auth.currentUser?.uid,
    });
};

const formatNumberOrNumberArray = (
  number?: number | number[]
): string | undefined => {
  if (number === undefined) {
    return undefined;
  }
  if (typeof number === "number") {
    return number.toString();
  }
  if (number.length === 0) {
    return undefined;
  }
  return number.join(",");
};

const formatNumberArray = (array: number[]): string | undefined => {
  if (array.length === 0) {
    return undefined;
  }
  return array.join(",");
};

const formatNumberOrBooleanOrStringAndUndefined = (
  value: number | boolean | string | undefined
): string | undefined => {
  if (value !== undefined) {
    return value.toString();
  }
  return undefined;
};

export const GTMEvents = {
  pageView: { event: "pageview" },
  userSignUp: { event: "user_signup" },
  userLogin: { event: "user_login" },
  userLogout: { event: "user_logout" },
  requestReservation: (hasMatch: boolean): GTMEventProps => {
    return {
      event: "request_reservation",
      has_match: hasMatch.toString(),
    };
  },
  toReservationFormPressed: (
    buttonType: string,
    shopUuid: string,
    matchId?: number,
    date?: string
  ): GTMEventProps => {
    // NOTE: undefinedの場合はundefinedを明示することで意図せずパラメータ送信されてしまうことを防いでいる
    // ex) 「観戦をネット予約する」を押下した後、リロードせずに「ネット予約する」を押下するとmatchIdが送信されてしまう
    return {
      event: "to_reservation_form_pressed",
      button_type: buttonType,
      shop_uuid: shopUuid,
      match_id: matchId ? matchId.toString() : undefined,
      date: date,
    };
  },
  reservationFrameOption: (
    reservation_uuid: string,
    plan_name: string,
    plan_description: string,
    state: string
  ): GTMEventProps => {
    return {
      event: "reservation_frame_option",
      reservation_uuid,
      plan_name,
      plan_description,
      state,
    };
  },
  shopRating: (shopUuid: string, rating: number): GTMEventProps => {
    return {
      event: "shop_rating",
      shop_uuid: shopUuid,
      rating: rating.toString(),
    };
  },
  matchesQuery: (
    baseYmd?: string,
    tournamentIds?: number | number[],
    teamIds?: number | number[],
    tournamentIdsTba?: number | number[],
    matchesReturned?: number
  ): GTMEventProps => {
    return {
      event: "matches_query",
      base_ymd: formatNumberOrBooleanOrStringAndUndefined(baseYmd),
      tournament_ids: formatNumberOrNumberArray(tournamentIds),
      team_ids: formatNumberOrNumberArray(teamIds),
      tournament_ids_tba: formatNumberOrNumberArray(tournamentIdsTba),
      matches_returned:
        formatNumberOrBooleanOrStringAndUndefined(matchesReturned),
    };
  },
  shopsQuery: (
    areaIds?: number | number[],
    combinedAreaIds?: number | number[],
    hasMatchId?: boolean,
    teamIds?: number | number[],
    tournamentIds?: number | number[],
    tournamentIdsTba?: number | number[],
    shopsReturned?: number,
    pagesReturned?: number
  ): GTMEventProps => {
    return {
      // in favor of the operation name from the flutter app
      event: "shops_query",
      area_ids: formatNumberOrNumberArray(areaIds),
      combined_area_ids: formatNumberOrNumberArray(combinedAreaIds),
      has_match_id: formatNumberOrBooleanOrStringAndUndefined(hasMatchId),
      team_ids: formatNumberOrNumberArray(teamIds),
      tournament_ids: formatNumberOrNumberArray(tournamentIds),
      tournament_ids_tba: formatNumberOrNumberArray(tournamentIdsTba),
      shops_returned: formatNumberOrBooleanOrStringAndUndefined(shopsReturned),
      pages_returned: formatNumberOrBooleanOrStringAndUndefined(pagesReturned),
    };
  },
  campaignLabelPressed: (shopUuid: string, linkUrl: string): GTMEventProps => {
    return {
      event: "campaign_label_pressed",
      shop_uuid: shopUuid,
      link_url: linkUrl,
    };
  },
  favoriteTeamSaved: (teamIds: Array<number>): GTMEventProps => {
    return {
      event: "favorite_team_saved",
      team_ids: formatNumberArray(teamIds),
    };
  },
  shopBenefitPressed: (
    shopUuid: string,
    benefitName: string
  ): GTMEventProps => {
    return {
      event: "shop_benefit_pressed",
      shop_uuid: shopUuid,
      benefit_name: benefitName,
    };
  },
  notificationPressed: (notificationId: number): GTMEventProps => {
    return {
      event: "notification_pressed",
      notification_id: notificationId.toString(),
    };
  },
  carouselPressed: (linkUrl: string): GTMEventProps => {
    return {
      event: "carousel_pressed",
      link_url: linkUrl,
    };
  },
  couponAppBannerPressed: (
    bannerType: "app_store" | "google_play"
  ): GTMEventProps => {
    return {
      event: "coupon_app_banner_pressed",
      banner_type: bannerType,
    };
  },
  telButtonPressed: (buttonLabel: string): GTMEventProps => ({
    event: "tel_button_pressed",
    button_label: buttonLabel,
  }),
  recommendedShopPressed: (displayOrder: number): GTMEventProps => ({
    event: "recommended_shop_pressed",
    display_order: displayOrder.toString(),
  }),
  shopCarouselPressed: {
    event: "shop_carousel_pressed",
  },
  addFavoriteTeamButtonPressed: (teamId: number): GTMEventProps => ({
    event: "add_favorite_team_button_pressed",
    team_id: teamId.toString(),
  }),
  removeFavoriteTeamButtonPressed: (teamId: number): GTMEventProps => ({
    event: "remove_favorite_team_button_pressed",
    team_id: teamId.toString(),
  }),
  shareButtonPressed: (
    buttonType: "twitter" | "facebook" | "line" | "native" | "copy" | "preview"
  ): GTMEventProps => ({
    event: "share_button_pressed",
    button_type: buttonType,
  }),
  neighborShopListPressed: (
    areaId: number,
    areaType: "MASTER_AREA" | "COMBINED_AREA" | "MASTER_PREFECTURE",
    isToShop: boolean,
    sourceUrl: string,
    destinationUrl: string
  ): GTMEventProps => ({
    event: "neighbor_shop_list_pressed",
    area_id: areaId.toString(),
    area_type: areaType,
    is_to_shop: isToShop.toString(),
    source_url: sourceUrl,
    destination_url: destinationUrl,
  }),
  toNewsDetailPagePressed: (
    from: string,
    newsId: number,
    favorite_team_ids?: number[]
  ): GTMEventProps => ({
    event: "to_news_detail_page_pressed",
    from,
    news_id: formatNumberOrNumberArray(newsId),
    favorite_team_ids: formatNumberOrNumberArray(favorite_team_ids),
  }),
  toNewsListPagePressed: (from: string, teamId?: number): GTMEventProps => ({
    event: "to_news_list_page_pressed",
    from,
    team_id: formatNumberOrNumberArray(teamId),
  }),
  toTeamPagePressed: (from: string, teamId: number): GTMEventProps => ({
    event: "to_team_page_pressed",
    from,
    team_id: formatNumberOrNumberArray(teamId),
  }),
};
