import type { UsersReservationPlanCancelPolicyRefundPercentageType } from "@graphql/__generated__/types";

export const buildCancelPolicyText = (
  cancelPolicyRefundPercentage: UsersReservationPlanCancelPolicyRefundPercentageType,
  cancelFeePercentage: number
) => {
  return [
    `当日キャンセル：${cancelPolicyRefundPercentage.onTheDay}%`,
    `前日キャンセル：${cancelPolicyRefundPercentage.before1Day}%`,
    `2日前キャンセル：${cancelPolicyRefundPercentage.before2Days}%`,
    `3日前キャンセル：${cancelPolicyRefundPercentage.before3Days}%`,
    "",
    "※リクエスト予約の承認前のキャンセルでは、店舗設定のキャンセルポリシーに応じたキャンセル料とキャンセル手数料は発生しません。",
    `※リクエスト予約の承認後にお客様都合でキャンセルすると、店舗設定のキャンセルポリシーに応じたキャンセル料と、キャンセル手数料（返金金額の${cancelFeePercentage}%）が発生します。`,
  ].join("\n");
};
