import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  cancelFeePercentage: number;
};
export const ReservationConfirmationAboutCancel: FC<Props> = ({
  cancelFeePercentage,
}) => (
  <div className={styles.aboutCancel}>
    <div className={styles.aboutCancelTitle}>キャンセルに関する注意事項</div>
    <ul className={styles.aboutCancelText}>
      <li>
        リクエスト予約の店舗承認前
        <ul>
          <li>
            店舗設定のキャンセルポリシーに応じたキャンセル料とキャンセル手数料は発生しません。
          </li>
        </ul>
      </li>
      <li>
        リクエスト予約の店舗承認後
        <ul>
          <li>
            お客様都合でキャンセルすると、店舗設定のキャンセルポリシーに応じたキャンセル料と、キャンセル手数料（返金額の
            {cancelFeePercentage}%）が発生します。
          </li>
          <li>
            決済金額からキャンセル料とキャンセル手数料を差し引いた金額をご返金いたします。
          </li>
          <li>
            ポイントを使ってのお支払いの場合、クレジット決済金額が優先的に使用されます。
          </li>
        </ul>
      </li>
      <li>
        人数変更は、システムでは対応しておりません
        <ul>
          <li>予約を一度キャンセルし再予約をお願いいたします。</li>
          <li>
            店舗設定のキャンセルポリシーに応じたキャンセル料と、手数料（返金額に対して
            {cancelFeePercentage}%）が発生しますのでご注意ください。
          </li>
        </ul>
      </li>
    </ul>
  </div>
);
