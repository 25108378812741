import { toUtcDatetime } from "@pages/shops/[uuid]/request_reservation/confirm";
import {
  ReservationDetailItem,
  ReservationDetailItemSingleValue,
} from "@components/ReservationDetailItem";
import RequestReservation from "@hooks/useRequestReservation";
import { ReservationPaymentTypeEnum } from "@hooks/useRequestReservation/__generated__/fragments";
import {
  toJpReservationDatetime,
  toJpTime,
  toLocaleStr,
} from "@libs/utils/date";
import { buildCancelPolicyText } from "@libs/utils/reservation";
import styles from "./index.module.css";
import type { FC } from "react";

export const ReservationConfirmationCard: FC = () => {
  const {
    requestReservation: {
      reservationFrame,
      comesAt,
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      shop,
      supportingTeamId,
      personNum,
      phoneNumber,
      cancelFeePercentage,
    },
    totalAmount,
    paymentType,
  } = RequestReservation.useContainer();
  const match = reservationFrame?.match;
  const hasSupportingTeam = match?.homeTeam && match.awayTeam;
  const newsArticles = reservationFrame?.newsArticles ?? [];
  const supportingTeam = [match?.homeTeam, match?.awayTeam].find(
    (t) => t?.id === supportingTeamId
  );
  const plan = reservationFrame?.reservationPlan;

  const matchText =
    match?.masterTournament?.name +
    "\n" +
    `${match?.homeTeamDisplayShortName} vs ${match?.awayTeamDisplayShortName} [${toJpTime(
      match?.startsAt ?? ""
    )} 試合開始]`;
  const reservationSupportingTeamText = hasSupportingTeam
    ? supportingTeam?.name || "どちらでもない"
    : "未設定";

  if (!reservationFrame || !plan) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.cardMain}>
        <div className={styles.shopName}>{shop?.name}</div>

        <div className={styles.date}>
          {toLocaleStr(reservationFrame?.date || "", "reservationDate")}{" "}
          {comesAt && comesAt[0] === "0"
            ? comesAt.slice(1, comesAt.length)
            : comesAt}
          来店
        </div>

        <div className={styles.planName}>{plan.name}</div>

        {plan.totalFee > 0 && (
          <div className={styles.cardPrice}>
            <ul className={styles.cardPriceList}>
              {plan.entranceFee > 0 && (
                <li className={styles.cardPriceItem}>
                  <div>チケット代</div>
                  <div>{plan.entranceFee.toLocaleString()}円</div>
                </li>
              )}
              {plan.fee > 0 && (
                <li className={styles.cardPriceItem}>
                  <div>飲食代</div>
                  <div>{plan.fee.toLocaleString()}円</div>
                </li>
              )}
              <li className={styles.cardPriceItem}>
                <div>ご予約人数</div>
                <div>{personNum}名</div>
              </li>
            </ul>

            <div className={styles.cardPriceTotal}>
              <div>合計</div>
              <div>
                <em>{totalAmount.toLocaleString()}円</em>
              </div>
            </div>
          </div>
        )}
      </div>

      <details className={styles.cardDetails}>
        <summary>ご予約の詳細</summary>
        <div className={styles.cardDetailsBody}>
          <ReservationDetailItem title="ご予約店舗">
            <ReservationDetailItemSingleValue value={shop?.name ?? ""} />
          </ReservationDetailItem>
          <ReservationDetailItem title="来店日時">
            <ReservationDetailItemSingleValue
              value={toJpReservationDatetime(
                toUtcDatetime(reservationFrame.date, comesAt ?? "")
              )}
            />
          </ReservationDetailItem>
          <ReservationDetailItem title="ご予約人数">
            <ReservationDetailItemSingleValue value={`${personNum}名`} />
          </ReservationDetailItem>
          {match && (
            <ReservationDetailItem title="観戦試合">
              <ReservationDetailItemSingleValue value={matchText} />
            </ReservationDetailItem>
          )}

          {match && (
            <ReservationDetailItem title="応援するチーム">
              <ReservationDetailItemSingleValue
                value={reservationSupportingTeamText}
              />
            </ReservationDetailItem>
          )}

          {newsArticles.length > 0 && (
            <ReservationDetailItem title="開催イベント">
              {newsArticles.map((na) => (
                <div key={na.id} className={styles.event}>
                  <ReservationDetailItemSingleValue value={na.title} />
                </div>
              ))}
            </ReservationDetailItem>
          )}
          <ReservationDetailItem title="ご予約プラン名">
            <ReservationDetailItemSingleValue value={plan.name} />
          </ReservationDetailItem>

          <ReservationDetailItem title="プラン内容">
            <ReservationDetailItemSingleValue value={plan.description} />
          </ReservationDetailItem>
          {plan.notes && (
            <ReservationDetailItem
              title={
                plan.cancelPolicy ? "注意事項" : "注意事項・キャンセルポリシー"
              }
            >
              <ReservationDetailItemSingleValue value={plan.notes} />
            </ReservationDetailItem>
          )}
          {paymentType === ReservationPaymentTypeEnum.OnSystem &&
            plan.cancelPolicy &&
            cancelFeePercentage && (
              <ReservationDetailItem title="キャンセルポリシー">
                <ReservationDetailItemSingleValue
                  value={buildCancelPolicyText(
                    plan.cancelPolicy.refundPercentage,
                    cancelFeePercentage
                  )}
                />
              </ReservationDetailItem>
            )}
          <ReservationDetailItem title="氏名（漢字）">
            <ReservationDetailItemSingleValue
              value={`${lastName} ${firstName}`}
            />
          </ReservationDetailItem>
          <ReservationDetailItem title="氏名（かな）">
            <ReservationDetailItemSingleValue
              value={`${lastNameKana} ${firstNameKana}`}
            />
          </ReservationDetailItem>
          <ReservationDetailItem title="電話番号">
            <ReservationDetailItemSingleValue value={phoneNumber} />
          </ReservationDetailItem>
        </div>
      </details>
    </div>
  );
};
