import styles from "./index.module.css";
import type { FC } from "react";

export const ReservationConfirmationNotes: FC = () => (
  <div className={styles.policy}>
    <div className={styles.policyTitle}>注意事項</div>
    <ul className={styles.policyText}>
      <li>リクエスト予約が承認されると、決済が自動的に処理されます。</li>
      <li>
        利用するFanstaポイントは、リクエスト予約完了時に減算されます。ただし、リクエスト予約承認前にキャンセルされた場合、またはリクエスト予約が却下された場合には、5日以内にポイントが返還されます。
      </li>
      <li>
        来店日に来店されなかった場合、決済の返金は致しかねます。また、利用ポイントも返還されません。
      </li>
      <li>
        決済処理は店舗からFanstaが委託され、Stripe,Inc.に再委託しています。
      </li>
      <li>
        観戦予定の試合が中止になった場合は、直接お店へご連絡ください。試合中止の場合のご予約キャンセルでは、お支払い金額と利用ポイントを全額返金いたします。
      </li>
    </ul>
  </div>
);
